"use client"
import React, {useState}    from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Container,
    ThemeProvider,
    Typography
}                           from '@material-ui/core'
import theme                from '../theme'
import Layout               from '../components/shared/layout'
import {
    practiceAreaConstants
}                           from '../practice-areas'
import Grid                 from '@material-ui/core/Grid'
import {
    makeStyles
}                           from '@material-ui/core/styles'
import Brilliant            from '../assets/textures/brilliant.png'
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore'
import BarristerList        from '../components/barristerList'
import useBarristersInOrder from '../hooks/useBarristersInOrder'
import {
    Barrister
}                           from '../../entities/barrister'
import {
    FadeAndSlide
}                           from '../components/animations/FadeSlideAnimations'
import Button               from '@material-ui/core/Button'
import ReactGA              from 'react-ga'
import {graphql}            from 'gatsby'

export const query = graphql`
    {
        barristerData: allSanityBarrister(sort: { order: ASC, fields: lastName }) {
            edges {
                node {
                    _id
                    preferredName
                    lastName
                    firstName
                    barristerGroup
                    slug {
                        current
                    }
                    barristerPortrait {
                        ...PortraitData
                    }
                    practices {
                        admissionToPracticeYear
                        silkYear
                        takenSilk
                        joinedBarYear
                    }
                    practiceAreas {
                        topLevelPracticeArea
                        commercialLaw {
                            subPracticeAreas
                        }
                        criminalLaw {
                            subPracticeAreas
                        }
                        employmentIndustrial {
                            subPracticeAreas
                        }
                        intellectualProperty {
                            subPracticeAreas
                        }
                        publicLaw {
                            subPracticeAreas
                        }
                        tortLaw {
                            subPracticeAreas
                        }
                    }
                }
            }
        }
    }
`


const useStyles = makeStyles((theme) => ({
    subPracticeAreaItem: {
        lineHeight: 1.3
    },
    showAllButton: {
        color: 'goldenrod',
        marginBottom: '0.5rem'
    },
    cardTitle: {
        fontSize: '2rem',
        marginBottom: '1rem'
    },
    subPracticeAreaList: {
        columns: 2,
        [theme.breakpoints.down('md')]: {
            columns: 1
        }
    },
    practiceAreaCard: {
        height: '100%'
    },
    cardRoot: {
        backgroundImage: `url('${Brilliant}')`,
        height: 'auto'
    },
    practiceAreaChip: {

        color: '#000',
        borderRadius: 0,
        borderColor: 'goldenrod',
        fontSize: '1.1rem',
        padding: '0.8rem 0.6rem',
        '&:hover': {
            background: 'rgba(0,0,0,0.87)',
            color: 'goldenrod',
            cursor: 'pointer'
        }
    },
    practiceAreasAccordionDetails: {
        display: 'block'
    },
    paAccordion: {
        width: '100%'
    },
    accordionSummary: {
        fontWeight: 500
    }
}))

type PracticeAreaPageProps = {
    data: { barristerData: { edges: { node: Barrister }[] } }
}

const PracticeAreasPage = (props: PracticeAreaPageProps) => {
    const classes = useStyles(theme)
    const [sidebarExpanded, setSidebarExpanded] = useState<string | false>(false)
    const [practiceAreaFilter, setPracticeAreaFilter] = useState<string | undefined>('')
    const [showBarristers, setShowBarristers] = useState<boolean>(true)

    const handleFilterTransition = (state: boolean) => {
        setShowBarristers(state)
    }

    const barristers = useBarristersInOrder(props.data.barristerData, 'lastName', practiceAreaFilter)


    const handleSideMenuChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        ReactGA.event({
            category: 'Practice Areas Accordion',
            action: `Click on ${panel}`,
            label: `Practice Area Interactions`
        })
        setPracticeAreaFilter(panel)
        setSidebarExpanded(panel)
    }

    return (
        <ThemeProvider theme={theme}>
            <Layout helmetData={{
                pageTitle: 'Practice Areas',
                metaDescription: 'Members of Level 22 Chambers practice across many areas of law and have a record of excellence in delivering practical legal assistance and advice.',
                slug: 'practice-areas'
            }} backgroundImage={''}>
                <Container maxWidth={'xl'}>
                    <Typography style={{color: '#FFF'}} variant={'h1'} component={'h1'}>
                        Practice Areas
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid container item sm={12} md={5} justify={'center'} alignItems={'flex-start'}
                              spacing={0}>
                            <section>
                                {
                                    practiceAreaFilter &&
                                    <Button fullWidth={true} className={classes.showAllButton}
                                            onClick={handleSideMenuChange('')}>Show
                                        All Barristers</Button>
                                }
                                {
                                    practiceAreaConstants && practiceAreaConstants.map((pa, idx) => (
                                                                  <Accordion key={idx} classes={{root: classes.cardRoot}}
                                                                             className={classes.paAccordion}
                                                                             expanded={sidebarExpanded === pa.id}
                                                                             onChange={handleSideMenuChange(sidebarExpanded ? sidebarExpanded === pa.id ? '' : pa.id : pa.id)}>
                                                                      <AccordionSummary
                                                                          expandIcon={<ExpandMoreIcon/>}
                                                                          aria-controls={`practice areas content for ${pa.title}`}
                                                                          id={`practiceAreas-header-${pa.id}`}
                                                                      >
                                                                          <Typography className={classes.accordionSummary} variant={'h5'}
                                                                                      component={'h2'}>{pa.title}</Typography>
                                                                      </AccordionSummary>
                                                                      <AccordionDetails className={classes.practiceAreasAccordionDetails}>
                                                                          <Grid container spacing={2} justify={'flex-start'}>
                                                                              {
                                                                                  pa.subPracticeAreas && pa.subPracticeAreas.map((spa, idx) =>
                                                                                                          <Grid key={idx} item>
                                                                                                              <Chip size={'small'}
                                                                                                                    className={classes.practiceAreaChip}
                                                                                                                    variant={'outlined'}
                                                                                                                    label={spa.title}/>
                                                                                                          </Grid>
                                                                                                      )
                                                                              }
                                                                          </Grid>
                                                                      </AccordionDetails>
                                                                  </Accordion>
                                                              )
                                                          )
                                }
                            </section>
                        </Grid>
                        <Grid container justify={'center'} item sm={12} md={7}>
                            <FadeAndSlide direction={'fade'} inProp={showBarristers}>
                                {barristers &&
                                 <BarristerList barristers={barristers} displayMode={'cards-only'} showGroups={false}/>}
                            </FadeAndSlide>
                        </Grid>
                    </Grid>
                </Container>
            </Layout>
        </ThemeProvider>
    )
}

export default PracticeAreasPage
